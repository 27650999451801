/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { ActionsList } from 'presentation/components/actionsList';
import { format } from 'date-fns';
import { makeReduxUpdateControllerConference } from 'main/factories/usecases/conference/UpdateControllerConferenceFactory';
import { makeRemoteDownloadConference } from 'main/factories/usecases/conference/DownloadConferenceFactory';
import { makeRemoteListRecordingsConference } from 'main/factories/usecases/conference/ListRecordingsConferenceFactory';
import { ListRecordingsConference } from 'domain/usecases/conference/remote';
import { addPadWithZeroes } from 'utils/formattedDate';
import { CircularProgress } from 'material-ui';
import { toast } from 'react-toastify';
import { ConferencesTags, ContainerTags } from './StyledList';
import { iColumnsConfig, iListMyConferencesNew } from './interface';
import Translator from '../i18n/Translator';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tr: {
    height: '40px',
  },
  container: {
    maxHeight: '100vh',
  },
  '.MuiTableCell-sizeSmall': {
    padding: '6px 21px 6px 15px',
  },
});

interface ownProps {
  dataList?: iListMyConferencesNew[];
}

type iColumns =
  | 'date'
  | 'duration'
  | 'title'
  | 'participants'
  | 'services'
  | 'actions';

const MyConferences: React.FC<ownProps> = ({ dataList }) => {
  const classes = useStyles();

  const columns: iColumnsConfig[] = [
    {
      id: 'date',
      label: `${Translator('Data e Hora')}`,
      minWidth: 50,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'duration',
      label: `${Translator('Duração')}`,
      minWidth: 80,
      align: 'right',
      alignTitle: 'right',
    },
    {
      id: 'title',
      label: `${Translator('Título')}`,
      minWidth: 520,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'participants',
      label: `${Translator('Participantes')}`,
      minWidth: 20,
      align: 'right',
      alignTitle: 'right',
    },
    {
      id: 'services',
      label: `${Translator('Serviços')}`,
      minWidth: 260,
      align: 'left',
      alignTitle: 'left',
    },
    {
      id: 'actions',
      label: `${Translator('Ações')}`,
      minWidth: 80,
      align: 'right',
      alignTitle: 'right',
    },
  ];

  const conferenceOptions = (conferences: iListMyConferencesNew) => {
    if (conferences?.services?.recording) {
      return (
        <div>
          <ActionsList
            actions={{
              viewMyConferencesDetails: () =>
                makeReduxUpdateControllerConference().updateController({
                  showDetails: true,
                  selected: conferences?.conference?.id,
                }),
              downloadConference: () => {
                makeRemoteListRecordingsConference()
                  .listRecordings({
                    conferenceId: conferences?.conference?.id,
                    query: {
                      disablePagination: true,
                    },
                  })
                  .then(res => {
                    if (
                      conferences?.conference?.id &&
                      res.records?.length > 0
                    ) {
                      makeRemoteDownloadConference()
                        .download({
                          conferenceId: conferences?.conference?.id,
                          recordingId: res.records?.[0]?.id,
                        })
                        .then(response => {
                          const scheduledDate = new Date(
                            conferences.conference?.scheduled,
                          );
                          const filename = `V4HAgenda_Reuniao_${addPadWithZeroes(
                            scheduledDate.getDate(),
                          )}-${addPadWithZeroes(
                            scheduledDate.getMonth() + 1,
                          )}-${scheduledDate.getFullYear()}_${addPadWithZeroes(
                            scheduledDate.getHours(),
                          )}-${addPadWithZeroes(
                            scheduledDate.getMinutes(),
                          )}.mp4`;

                          const a = document.createElement('a');
                          a.href = response.url;
                          a.setAttribute('download', filename);
                          a.style.display = 'none';
                          a.target = '_blank';
                          document.body.appendChild(a);
                          a.click();
                          document.body.removeChild(a);

                          toast.success('Gravação baixada com sucesso');
                        })
                        .catch(() => {
                          console.log('error');
                        });
                    }
                  })
                  .catch(() => {
                    console.log('error');
                  });
              },
            }}
          />
        </div>
      );
    }
    return (
      <ActionsList
        actions={{
          viewMyConferencesDetails: () =>
            makeReduxUpdateControllerConference().updateController({
              showDetails: true,
              selected: conferences?.conference?.id,
            }),
        }}
      />
    );
  };

  const RenderComponents: React.FC<{
    id: iColumns;
    conferences: iListMyConferencesNew;
  }> = ({ id, conferences }) => {
    const timestampDate = new Date(conferences?.conference?.scheduled);

    switch (id) {
      case 'date':
        return <div>{format(timestampDate, 'dd/MM/yyyy - HH:mm')}</div>;
      case 'duration':
        const splitHour = conferences?.conference?.duration?.split('h');

        return (
          <text
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              textAlign: 'right',
            }}
          >
            {splitHour[0] !== '0' && <div>{`${splitHour[0]}h`}</div>}
            {splitHour[1] !== '0' && <div>{`${splitHour[1]}`}</div>}
            {/* {splitHour[0] === '0' && splitHour[1] === '0m' && (
              <div>- de um minuto</div>
            )} */}
          </text>
        );
      case 'title':
        return <text>{conferences?.conference?.title}</text>;
      case 'participants':
        return <text>{conferences?.conference?.participants}</text>;
      case 'services':
        const emptyServices =
          !conferences?.services?.recording &&
          !conferences?.services?.libras &&
          !conferences?.services?.transcription &&
          !conferences?.services?.dlt;
        return (
          <ContainerTags>
            {conferences?.services?.recording && (
              <ConferencesTags status="RECORDING">Gravação</ConferencesTags>
            )}
            {conferences?.services?.libras && (
              <ConferencesTags status="LIBRAS">Libras</ConferencesTags>
            )}
            {conferences?.services?.transcription && (
              <ConferencesTags status="TRANSCRIPTION">
                Transcrição
              </ConferencesTags>
            )}
            {conferences?.services?.dlt && (
              <ConferencesTags status="BLOCKCHAIN">Blockchain</ConferencesTags>
            )}
            {emptyServices && <text>--</text>}
          </ContainerTags>
        );
      case 'actions':
        return conferenceOptions(conferences);
      default:
        return <div />;
    }
  };

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {columns.map(item => {
              return (
                <TableCell
                  align={item.alignTitle}
                  style={{ padding: '13px 16px' }}
                >
                  <div
                    style={{
                      color: 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    {item.label}
                  </div>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          <>
            {dataList?.map((conferences: iListMyConferencesNew) => (
              <TableRow>
                {columns.map((columnProps: any) => {
                  return (
                    <TableCell
                      align={columnProps.align}
                      style={{
                        maxWidth: columnProps.maxWidth,
                        minWidth: columnProps.minWidth,
                        fontSize: columnProps.fontSize,
                      }}
                    >
                      <RenderComponents
                        id={columnProps.id}
                        conferences={conferences}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MyConferences;
