/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { MessageOptions } from 'domain/interfaces/redux/message';
import { makeRemoteAgreedTerms } from 'main/factories/usecases/user/AgreeTermsFactory';
import { ThemeContext } from 'App';
import { Button } from '../../UI';
import { ownProps } from '../interface';
import {
  Body,
  Container,
  Content,
  Header,
  ButtonsContainer,
  ContainerCheckbox,
} from './styles/StyledFirstAccessModal';

const FirstAccessModal: React.FC<ownProps> = ({ message }) => {
  const msgName = MessageOptions.firstAccess;

  const { active, actionOk, actionCancel, userId } = message;

  const [checked, setChecked] = useState(false);
  const { theme } = useContext(ThemeContext);

  const handleSubmit = useCallback(() => {
    if (!userId) return;

    makeRemoteAgreedTerms()
      .agree({
        body: {
          agreedTerms: checked,
        },
        userId,
      })
      .then(() => {
        setChecked(false);
        actionOk?.();
      })
      .catch(() => {
        // toast.error(
        //   'Não foi possível aceitar os termos, por favor, tente novamente.',
        // );
      });
  }, [actionOk, checked, userId]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        actionCancel?.();
      }

      if (e.key === 'Enter') {
        actionCancel?.();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  });

  return (
    <>
      {msgName === active ? (
        <Container>
          <Body>
            <Header>
              <span>Termos de Uso e Política de Privacidade</span>
            </Header>
            <Content>
              <p>
                Para usar o V4H Agenda você deve ler e concordar com os termos
                de uso do serviço e com a política de privacidade.
              </p>
              <ContainerCheckbox>
                <input
                  type="checkbox"
                  checked={checked}
                  onChange={() => setChecked(prevState => !prevState)}
                />
                Concordo com os
                <a
                  href={
                    theme.emailParams.serviceTerms ??
                    'https://codata.pb.gov.br/midias/politica-geral-de-seguranca-da-informacao-2013-pgsi/t-si-001-2013-codata-2013-termo-de-uso-dos-sistemas-internos.pdf'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  Termos de Uso
                </a>
                e
                <a
                  href={
                    theme.emailParams.serviceTerms ??
                    'https://minio.homolog.v4h.codata.pb.gov.br/public/politica_privacidade.html'
                  }
                  target="_blank"
                  rel="noreferrer"
                >
                  Política de Privacidade
                </a>
              </ContainerCheckbox>
            </Content>
            <ButtonsContainer>
              <Button
                id="button_cancel"
                rounded
                variant="secundary"
                size="small"
                autoFocus
                onClick={actionCancel}
              >
                Fechar
              </Button>
              <Button
                id="button_confirm"
                rounded
                variant="primary"
                size="small"
                autoFocus
                onClick={handleSubmit}
                disabled={!checked}
              >
                Enviar
              </Button>
            </ButtonsContainer>
          </Body>
        </Container>
      ) : null}
    </>
  );
};

export default FirstAccessModal;
